/* eslint-disable react/jsx-no-target-blank */
// constants
import { Images, LABELS, ROUTE_CONSTANTS } from "Shared";

// libs
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

// actions
import { setSideBar } from "Redux/Actions/Loader";

// styles
import "./navbar.scss";
import { storyBoardURL } from "envData";

const FirstHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const showSidebar = useSelector((state) => state?.loading?.sidebar || false);

  const handleSideBar = () => {
    if (showSidebar) {
      document.body.classList.remove("is-menu-active");
      dispatch(setSideBar(false));
    } else {
      document.body.classList.add("is-menu-active");
      dispatch(setSideBar(true));
    }

    dispatch(setSideBar(!showSidebar));
  };

  const handleResizeEvent = () => {
    dispatch(setSideBar(false));
    document.body.classList.remove("is-menu-active");
  };

  // Sidebar state false for mobile devices
  useEffect(() => {
    window.addEventListener("resize", handleResizeEvent);
    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const header = document.querySelector(".main_head");
    const toggleClass = "is-sticky";
    window.addEventListener("scroll", () => {
      const windowHeight = window.innerHeight;
      const currentScroll = window.scrollY;

      // Calculate the percentage scroll threshold based on window height
      const scrollThreshold = windowHeight * 0.5; // Adjust the percentage as needed
      if (currentScroll > scrollThreshold) {
        header.classList.add(toggleClass);
      } else {
        header.classList.remove(toggleClass);
      }
    });
  }, []);

  return (
    <>
      <header className="main_head">
        <div className="container-fluid px-xxl-5 px-xl-4">
          <nav className="navbar navbar-expand-xl navbar-light bg-white d-flex align-items-center justify-content-between">
            <span
              className="navbar-brand my-cursor-pointer"
              onClick={() => {
                document.body.classList.remove("is-menu-active");
                dispatch(setSideBar(false));
                navigate({
                  pathname: ROUTE_CONSTANTS.DASHBOARD,
                });
              }}
            >
              <img
                src={Images.miniHeartFoundation}
                className="d-inline-block"
                width="180"
                alt="miniHeart logo"
              />
            </span>
            <div className="d-flex align-items-center gap-3 gap-lg-5 logo_grp">
              <img
                src={Images.RegisterCharity}
                width="63"
                alt="Registered Charity"
              />
              <Link
                type="link"
                className="donate_link"
                aria-current="page"
                target="_self"
                to={process.env.REACT_APP_DOMAIN_MINI_DONATION_PAGE}
              >
                <img
                  src={Images.DonateNow}
                  className="d-inline-block donateNow"
                  width="70"
                  alt="Donate Now heart"
                />
              </Link>
              <button
                className="navbar-toggler hamburger"
                type="button"
                data-toggle="collapse"
                onClick={handleSideBar}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>

            <div
              id="navbarSupportedContent"
              className={`collapse navbar-collapse main_navbar d-xl-flex justify-content-between align-items-start`}
            >
              <ul className="navbar-nav me-auto w-100 align-items-start main_navbar_menu">
                <li
                  className={`nav-item ${location.pathname === ROUTE_CONSTANTS.DASHBOARD
                    ? "active"
                    : ""
                    } `}
                >
                  <span
                    className="nav-link"
                    onClick={() => {
                      handleSideBar();
                      navigate({
                        pathname: ROUTE_CONSTANTS.DASHBOARD,
                      });
                    }}
                  >
                    {LABELS.HOME}
                  </span>
                </li>
                <li
                  className={`nav-item ${location.pathname === ROUTE_CONSTANTS.CARE_PROGRAM
                    ? "active"
                    : ""
                    } `}
                >
                  <span
                    className="nav-link "
                    aria-current="page"
                    onClick={() => {
                      handleSideBar();
                      navigate({
                        pathname: ROUTE_CONSTANTS.CARE_PROGRAM,
                      });
                    }}
                  >
                    {LABELS.WE_CARE_PROGRAM}
                  </span>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    target="_self"
                    href={storyBoardURL}
                  >
                    {LABELS.STORY_BOARD}
                  </a>
                </li>

                <li
                  className={`nav-item ${location.pathname === ROUTE_CONSTANTS.LEADER_SHIP
                    ? "active"
                    : ""
                    } `}
                >
                  <span
                    className="nav-link"
                    onClick={() => {
                      handleSideBar();
                      navigate({
                        pathname: ROUTE_CONSTANTS.LEADER_SHIP,
                      });
                    }}
                  >
                    Leadership Team
                  </span>
                </li>
                <li
                  className={`nav-item ${location.pathname === ROUTE_CONSTANTS.TERM_AND_CONDITIONS
                    ? "active"
                    : ""
                    } `}
                >
                  <span
                    className="nav-link"
                    onClick={() => {
                      handleSideBar();
                      navigate({
                        pathname: ROUTE_CONSTANTS.TERM_AND_CONDITIONS,
                      });
                    }}
                  >
                    {LABELS.TERMS_AND_CONDITIONS}
                  </span>
                </li>
                <li
                  className={`nav-item ${location.pathname === ROUTE_CONSTANTS.PRIVACY_POLICY_PAGE
                    ? "active"
                    : ""
                    } `}
                >
                  <span
                    className="nav-link"
                    onClick={() => {
                      handleSideBar();
                      navigate({
                        pathname: ROUTE_CONSTANTS.PRIVACY_POLICY_PAGE,
                      });
                    }}
                  >
                    {LABELS.PRIVACY_POLICY}
                  </span>
                </li>
                <li
                  className={`nav-item ${location.pathname === ROUTE_CONSTANTS.CONTACT_US
                    ? "active"
                    : ""
                    } `}
                >
                  <span
                    className="nav-link"
                    onClick={() => {
                      handleSideBar();
                      navigate({
                        pathname: ROUTE_CONSTANTS.CONTACT_US,
                      });
                    }}
                  >
                    {LABELS.CONTACT_US}
                  </span>
                </li>
                <Link
                  type="button"
                  className="btn btn-md btn-primary-red member-link"
                  aria-current="page"
                  target="_self"
                  to={process.env.REACT_APP_DOMAIN_MEMBER}
                >
                  {LABELS.BECOME_MEMBER}
                </Link>
              </ul>
            </div>
            {showSidebar ? (
              <div
                className={`offcanvas-backdrops ${showSidebar ? "show" : ""} `}
                onClick={handleSideBar}
              ></div>
            ) : null}
          </nav>
        </div>
      </header>
    </>
  );
};

export default FirstHeader;
